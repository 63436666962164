import { createSlice } from '@reduxjs/toolkit';

const wordSlice = createSlice({
  name: 'word',
  initialState: {
    isShowVideoModal: false,
    selectedVideos: [],
  },
  reducers: {
    showVideoModal(state, action) {
      state.isShowVideoModal = true;
    },
    hideVideoModal(state) {
      state.isShowVideoModal = false;
    },
    setSelectedVideos(state, action) {
      state.selectedVideos = action.payload;
    },
    addSelectedVideo(state, action) {
      // const findVideo = state.selectedVideos.find((item) => item.id === action.payload.id);
      // if( !findVideo ){
      state.selectedVideos.push(action.payload);
      // }
      state.isShowVideoModal = false;
    },
    removeSelectedVideo(state, action) {
      const index = state.selectedVideos.findIndex((item) => item.id === action.payload.id);
      state.selectedVideos.splice(index, 1);
    },
  },
});

export const wordActions = wordSlice.actions;
export default wordSlice.reducer;
