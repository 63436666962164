import axios from 'axios';
import TokenService from './tokenService';
import jwt from 'jwt-decode';
import daysjs from 'dayjs';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    const originalConfig = config;

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const accessToken = TokenService.getLocalAccessToken();

    const excludeEndpoints = ['/auth/login', '/auth/refresh', '/login'];
    let isExclude = false;
    excludeEndpoints.forEach((endpoint) => {
      if (
        originalConfig.url === endpoint ||
        originalConfig.url === process.env.REACT_APP_API_URL + endpoint ||
        originalConfig.url ===
          process.env.REACT_APP_API_URL + process.env.REACT_APP_BASENAME + endpoint
      ) {
        isExclude = true;
      }
    });

    if (!isExclude && err.response && err.response.status === 401) {
      // Access Token was expired
      if (originalConfig._retry) {
        TokenService.removeUser();
        location.href = process.env.REACT_APP_URL + process.env.REACT_APP_BASENAME + '/login';
        return Promise.reject(err);
      }

      if (accessToken) {
        originalConfig._retry = true;

        try {
          const response = await instance.post('/auth/refresh', {
            token: TokenService.getLocalAccessToken(),
          });

          const refreshToken = response.data.data?.access_token;
          if (!refreshToken) {
            TokenService.removeUser();
            location.href = process.env.REACT_APP_URL + process.env.REACT_APP_BASENAME + '/login';
            return Promise.reject(err);
          }

          TokenService.setLocalAccessToken(refreshToken);
          // console.info("[token] Access token refreshed!");
          return instance(originalConfig);
        } catch (_error) {
          // console.log("[token] Refresh token error: ", _error);
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default instance;
