import jwt from 'jwt-decode';
import api from '../services/baseApi';
import TokenService from '../services/tokenService';

export function login(username, password) {
  return api
    .post('/auth/login', {
      username,
      password,
    })
    .then((response) => {
      console.log(response.data.data);
      if (response.data.result) {
        TokenService.setLocalAccessToken(response.data.data.access_token);
      }

      return response.data;
    });
}

export function logout() {
  TokenService.removeUser();
  location.href = process.env.REACT_APP_URL + process.env.REACT_APP_BASENAME + '/login';
}

export function getUser() {
  try {
    const token = TokenService.getLocalAccessToken();
    return jwt(token);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default {
  login,
  logout,
  getUser,
};
